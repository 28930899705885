define("ember-social-share/templates/components/vk-share-button", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "modules/ember-social-share/templates/components/vk-share-button.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1, "xmlns:svg", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el1, "version", "1.0");
        dom.setAttribute(el1, "viewBox", "0 0 800 800");
        var el2 = dom.createTextNode("\n   ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("path");
        dom.setAttribute(el2, "d", "m393.013488,609.426403l39.26903,0c0,0 11.85891,-1.645384 17.92254,-9.857813c5.57318,-7.548444 5.39504,-21.7134 5.39504,-21.7134c0,0 -0.76808,-66.328382 23.68311,-76.095923c24.11342,-9.627742 55.07011,64.103457 87.87991,92.456697c24.8121,21.448392 43.66697,16.753911 43.66697,16.753911l87.73879,-1.543482c0,0 45.89484,-3.564535 24.13192,-48.98911c-1.78138,-3.710152 -12.67788,-33.601025 -65.23786,-95.013615c-55.01922,-64.278202 -47.64384,-53.878724 18.62584,-165.063808c40.35868,-67.711678 56.49059,-109.047522 51.44951,-126.750805c-4.80279,-16.867502 -34.48938,-12.411821 -34.48938,-12.411821l-98.78566,0.768816c0,0 -7.32911,-1.255156 -12.75654,2.833565c-5.30945,3.998459 -8.7172,13.340814 -8.7172,13.340814c0,0 -15.64145,52.393487 -36.48592,96.958973c-43.98854,94.023457 -61.58025,99.000414 -68.77055,93.152709c-16.72879,-13.608742 -12.54833,-54.659189 -12.54833,-83.830742c0,-91.122907 10.97979,-129.115538 -21.38119,-138.950043c-10.73687,-3.26167 -18.64666,-5.419611 -46.11,-5.771992c-35.25052,-0.451393 -65.07824,0.136869 -81.97128,10.553825c-11.23889,6.928146 -19.90982,22.362826 -14.62583,23.251053c6.53096,1.094989 21.31411,5.023563 29.15218,18.448831c10.12611,17.342173 9.77215,56.272536 9.77215,56.272536c0,0 5.8184,107.26525 -13.58477,120.585685c-13.31408,9.138493 -31.58133,-9.517091 -70.79947,-94.821401c-20.09027,-43.694728 -35.2644,-91.999475 -35.2644,-91.999475c0,0 -2.92193,-9.024922 -8.14114,-13.856272c-6.32968,-5.853535 -15.17412,-7.708601 -15.17412,-7.708601l-93.87642,0.771736c0,0 -14.08914,0.495069 -19.26671,8.2095c-4.60614,6.866981 -0.36781,21.052335 -0.36781,21.052335c0,0 73.49006,216.440911 156.71058,325.51465c76.31481,100.01678 162.95701,93.452664 162.95701,93.452664");
        dom.setAttribute(el2, "id", "path2442");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("span");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        return morphs;
      },
      statements: [["content", "yield", ["loc", [null, [7, 6], [7, 15]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});