define('ember-css-modules/mixins/controller-mixin', ['exports', 'ember', 'ember-getowner-polyfill'], function (exports, _ember, _emberGetownerPolyfill) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    styles: _ember['default'].computed('__styles__', function () {
      _ember['default'].deprecate('Using the implicit `controller.styles` computed is deprecated. In a template, use the {{local-class}} helper, ' + 'and in JavaScript, import the styles hash and reference it directly.', false, {
        id: 'ember-css-modules.styles-computed',
        until: '0.7.0'
      });

      return this.get('__styles__');
    }).readOnly(),

    __styles__: _ember['default'].computed(function () {
      // If styles is an explicitly set hash, defer to it. Otherwise, use the resolver.
      if (Object.getPrototypeOf(this.styles) === Object.prototype) {
        return this.styles;
      }

      var key = this._debugContainerKey;
      if (!key) {
        return;
      }

      return (0, _emberGetownerPolyfill['default'])(this).resolveRegistration('styles:' + key.split(':')[1]);
    })
  });
});