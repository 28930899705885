define('ivy-tabs/components/ivy-tabs-tablist', ['exports', 'ember-component', 'ember-computed', 'ivy-tabs/templates/components/ivy-tabs-tablist', 'ember-evented/on', 'ember-array/utils', 'ember-utils', 'ember-runloop'], function (exports, _emberComponent, _emberComputed, _ivyTabsTemplatesComponentsIvyTabsTablist, _emberEventedOn, _emberArrayUtils, _emberUtils, _emberRunloop) {
  'use strict';

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabListComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  exports['default'] = _emberComponent['default'].extend({
    layout: _ivyTabsTemplatesComponentsIvyTabsTablist['default'],

    attributeBindings: ['aria-multiselectable'],
    classNames: ['ivy-tabs-tablist'],

    init: function init() {
      this._super.apply(this, arguments);
      (0, _emberRunloop.once)(this, this._registerWithTabsContainer);
    },

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      (0, _emberRunloop.once)(this, this._unregisterWithTabsContainer);
    },

    /**
     * Tells screenreaders that only one tab can be selected at a time.
     *
     * @property aria-multiselectable
     * @type String
     * @default 'false'
     */
    'aria-multiselectable': 'false',

    /**
     * The `role` attribute of the tab list element.
     *
     * See http://www.w3.org/TR/wai-aria/roles#tablist
     *
     * @property ariaRole
     * @type String
     * @default 'tablist'
     */
    ariaRole: 'tablist',

    /**
     * Gives focus to the selected tab.
     *
     * @method focusSelectedTab
     */
    focusSelectedTab: function focusSelectedTab() {
      this.get('selectedTab').$().focus();
    },

    /**
     * Event handler for navigating tabs via arrow keys. The left (or up) arrow
     * selects the previous tab, while the right (or down) arrow selects the next
     * tab.
     *
     * @method navigateOnKeyDown
     * @param {Event} event
     */
    navigateOnKeyDown: (0, _emberEventedOn['default'])('keyDown', function (event) {
      switch (event.keyCode) {
        case 37: /* left */
        case 38:
          /* up */
          this.selectPreviousTab();
          break;
        case 39: /* right */
        case 40:
          /* down */
          this.selectNextTab();
          break;
        default:
          return;
      }

      event.preventDefault();
      (0, _emberRunloop.scheduleOnce)('afterRender', this, this.focusSelectedTab);
    }),

    /**
     * Adds a tab to the `tabs` array.
     *
     * @method registerTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    registerTab: function registerTab(tab) {
      this.get('tabs').pushObject(tab);
      (0, _emberRunloop.once)(this, this.selectTab);
    },

    /**
     * Selects the next tab in the list, if any.
     *
     * @method selectNextTab
     */
    selectNextTab: function selectNextTab() {
      var selectedTab = this.get('selectedTab');
      var tabs = this.get('tabs');
      var length = tabs.get('length');

      var idx = selectedTab.get('index');
      var tab = undefined;

      do {
        idx++;
        // Next from the last tab should select the first tab.
        if (idx === length) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },

    /**
     * Selects the previous tab in the list, if any.
     *
     * @method selectPreviousTab
     */
    selectPreviousTab: function selectPreviousTab() {
      var selectedTab = this.get('selectedTab');
      var tabs = this.get('tabs');
      var length = tabs.get('length');

      var idx = selectedTab.get('index');
      var tab = undefined;

      do {
        idx--;
        // Previous from the first tab should select the last tab.
        if (idx < 0) {
          idx = length - 1;
        }
        // This would only happen if there are no tabs, so stay at 0.
        if (idx < 0) {
          idx = 0;
        }

        tab = tabs.objectAt(idx);
      } while (tab && tab.isDestroying && tab !== selectedTab);

      if (tab) {
        tab.select();
      }
    },

    selectTab: function selectTab() {
      var selection = this.get('selection');

      if ((0, _emberUtils.isNone)(selection) || this.get('tabs.length') === 1) {
        this.selectTabByIndex(0);
      } else {
        this.selectTabByModel(selection);
      }
    },

    /**
     * The currently-selected `ivy-tabs-tab` instance.
     *
     * @property selectedTab
     * @type IvyTabs.IvyTabComponent
     */
    selectedTab: (0, _emberComputed['default'])('selection', 'tabs.@each.model', function () {
      return this.get('tabs').findBy('model', this.get('selection'));
    }),

    /**
     * Select the tab at `index`.
     *
     * @method selectTabByIndex
     * @param {Number} index
     */
    selectTabByIndex: function selectTabByIndex(index) {
      var tab = this.get('tabs').objectAt(index);

      if (tab) {
        tab.select();
      }
    },

    selectTabByModel: function selectTabByModel(model) {
      var tab = this.get('tabs').findBy('model', model);

      if (tab) {
        tab.select();
      }
    },

    tabs: (0, _emberComputed['default'])(function () {
      return (0, _emberArrayUtils.A)();
    }).readOnly(),

    /**
     * The `ivy-tabs` component.
     *
     * @property tabsContainer
     * @type IvyTabs.IvyTabsComponent
     * @default null
     */
    tabsContainer: null,

    /**
     * Removes a tab from the `tabs` array.
     *
     * @method unregisterTab
     * @param {IvyTabs.IvyTabComponent} tab
     */
    unregisterTab: function unregisterTab(tab) {
      var index = tab.get('index');

      if (tab.get('isSelected')) {
        if (index === 0) {
          this.selectNextTab();
        } else {
          this.selectPreviousTab();
        }
      }

      this.get('tabs').removeObject(tab);
    },

    _registerWithTabsContainer: function _registerWithTabsContainer() {
      this.get('tabsContainer').registerTabList(this);
      (0, _emberRunloop.once)(this, this.selectTab);
    },

    _unregisterWithTabsContainer: function _unregisterWithTabsContainer() {
      this.get('tabsContainer').unregisterTabList(this);
    }
  });
});