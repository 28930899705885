define('ember-cli-notifications/services/notification-messages-service', ['exports', 'ember'], function (exports, _ember) {
    'use strict';

    var assign = _ember['default'].assign || _ember['default'].merge;

    exports['default'] = _ember['default'].ArrayProxy.extend({
        content: _ember['default'].A(),

        defaultClearDuration: 3200,
        defaultAutoClear: false,

        addNotification: function addNotification(options) {
            // If no message is set, throw an error
            if (!options.message) {
                throw new Error("No notification message set");
            }

            var notification = _ember['default'].Object.create({
                message: options.message,
                type: options.type || 'info', // info, success, warning, error
                autoClear: _ember['default'].isEmpty(options.autoClear) ? this.get('defaultAutoClear') : options.autoClear,
                clearDuration: options.clearDuration || this.get('defaultClearDuration'),
                onClick: options.onClick,
                htmlContent: options.htmlContent || false,
                cssClasses: options.cssClasses
            });

            this.pushObject(notification);

            if (notification.autoClear) {
                notification.set('remaining', notification.get('clearDuration'));
                this.setupAutoClear(notification);
            }

            return notification;
        },

        // Helper methods for each type of notification
        error: function error(message, options) {
            this.addNotification(assign({
                message: message,
                type: 'error'
            }, options));
        },

        success: function success(message, options) {
            this.addNotification(assign({
                message: message,
                type: 'success'
            }, options));
        },

        info: function info(message, options) {
            this.addNotification(assign({
                message: message,
                type: 'info'
            }, options));
        },

        warning: function warning(message, options) {
            this.addNotification(assign({
                message: message,
                type: 'warning'
            }, options));
        },

        removeNotification: function removeNotification(notification) {
            var _this = this;

            if (!notification) {
                return;
            }
            notification.set('dismiss', true);
            // Delay removal from DOM for dismissal animation
            _ember['default'].run.later(this, function () {
                _this.removeObject(notification);
            }, 500);
        },

        setupAutoClear: function setupAutoClear(notification) {
            var _this2 = this;

            notification.set('startTime', Date.now());

            var timer = _ember['default'].run.later(this, function () {
                // Hasn't been closed manually
                if (_this2.indexOf(notification) >= 0) {
                    _this2.removeNotification(notification);
                }
            }, notification.get('remaining'));

            notification.set('timer', timer);
        },

        pauseAutoClear: function pauseAutoClear(notification) {
            _ember['default'].run.cancel(notification.get('timer'));

            var elapsed = Date.now() - notification.get('startTime');
            var remaining = notification.get('clearDuration') - elapsed;
            notification.set('remaining', remaining);
        },

        clearAll: function clearAll() {
            this.set('content', _ember['default'].A());
        },

        setDefaultAutoClear: function setDefaultAutoClear(autoClear) {
            if (_ember['default'].typeOf(autoClear) !== 'boolean') {
                throw new Error('Default auto clear preference must be a boolean');
            }

            this.set('defaultAutoClear', autoClear);
        },

        setDefaultClearDuration: function setDefaultClearDuration(clearDuration) {
            if (_ember['default'].typeOf(clearDuration) !== 'number') {
                throw new Error('Clear duration must be a number');
            }

            this.set('defaultClearDuration', clearDuration);
        }
    });
});