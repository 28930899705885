define('ivy-tabs/components/ivy-tabs', ['exports', 'ember-component', 'ember-computed', 'ivy-tabs/templates/components/ivy-tabs', 'ember-array/utils'], function (exports, _emberComponent, _emberComputed, _ivyTabsTemplatesComponentsIvyTabs, _emberArrayUtils) {
  'use strict';

  /**
   * @module ivy-tabs
   */

  /**
   * @class IvyTabsComponent
   * @namespace IvyTabs
   * @extends Ember.Component
   */
  exports['default'] = _emberComponent['default'].extend({
    layout: _ivyTabsTemplatesComponentsIvyTabs['default'],

    classNames: ['ivy-tabs'],

    /**
     * Set this to the model of the tab you'd like to be selected. Usually it is
     * bound to a controller property that is used as a query parameter, but can
     * be bound to anything.
     *
     * @property selection
     * @type Object
     */
    selection: null,

    /**
     * Registers the `ivy-tabs-tablist` instance.
     *
     * @method registerTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    registerTabList: function registerTabList(tabList) {
      this.set('tabList', tabList);
    },

    /**
     * Adds a panel to the `tabPanels` array.
     *
     * @method registerTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    registerTabPanel: function registerTabPanel(tabPanel) {
      this.get('tabPanels').pushObject(tabPanel);
    },

    tabPanels: (0, _emberComputed['default'])(function () {
      return (0, _emberArrayUtils.A)();
    }).readOnly(),

    /**
     * Removes the `ivy-tabs-tablist` component.
     *
     * @method unregisterTabList
     * @param {IvyTabs.IvyTabListComponent} tabList
     */
    unregisterTabList: function unregisterTabList() /* tabList */{
      this.set('tabList', null);
    },

    /**
     * Removes a panel from the `tabPanels` array.
     *
     * @method unregisterTabPanel
     * @param {IvyTabs.IvyTabPanelComponent} tabPanel
     */
    unregisterTabPanel: function unregisterTabPanel(tabPanel) {
      this.get('tabPanels').removeObject(tabPanel);
    }
  });
});